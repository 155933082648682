/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { useRef } from 'react';
import dynamic from "next/dynamic";
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import MDBox from '/components/MDBox';
import review1 from '/assets/img/review1.jpg';
import review2 from "/assets/img/review2.jpg";
import cardella from '/assets/img/cardella.jpg';
import kupersmith from '/assets/img/kupersmith.jpg';
import MDTypography from "/components/MDTypography";

const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const ChevronLeftRounded = dynamic(() => import("@mui/icons-material/ChevronLeftRounded"), { loading: () => null });
const ChevronRightRounded = dynamic(() => import("@mui/icons-material/ChevronRightRounded"), { loading: () => null });
const ComplexReviewCard = dynamic(() => import("/examples/ComplexReviewCard"), { loading: () => null });

function ReviewsSlider() {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  SwiperCore.use([Autoplay, Navigation]);
  const navigationStyles = {
    position: 'absolute',
    top: 0,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '15%',
    height: '100%',
    textAlign: 'center',
    opacity: 0.5,
    cursor: 'pointer',
    transition: 'opacity 0.15s ease',
    '&:hover, &:focus': {
      opacity: 1,
    },
  };

  return (
    <MDBox py={8} position="relative">
      <Swiper
        onInit={({ params, navigation }) => {
          const { navigation: nav } = params;
          nav.prevEl = navigationPrevRef.current;
          nav.nextEl = navigationNextRef.current;
          navigation.init();
          navigation.update();
        }}
        autoplay={{ delay: 5000 }}
        speed={800}
        spaceBetween={0}
        slidesPerView={1}
        loop
      >
        <SwiperSlide>
          <Container>
            <ComplexReviewCard
              image={review1}
              title="Great job, the design really helped, highly recommend!"
              review="Lenny was creative, he comes back to check on the health of the project, enjoying our space in a way we didn't think we could!"
              author={{ logo: cardella, name: 'Victor Cardella', role: 'Home Owner - South Huntington' }}
            />
          </Container>
        </SwiperSlide>
        <SwiperSlide>
          <Container>
            <ComplexReviewCard
              image={review2}
              title="Very happy! Everything looks great!"
              review="Our lawn looks great, our shrubs look great, Lenny is fabulous, our lawn looks great, our shrubs looks great, our property looks great, we can entertain!"
              author={{ logo: kupersmith, name: 'Laura Kupersmith', role: 'Home Owner - Town' }}
            />
          </Container>
        </SwiperSlide>
        <MDTypography
          variant="h2"
          color="dark"
          sx={{ ...navigationStyles, left: 0 }}
          ref={navigationPrevRef}
        >
          <ChevronLeftRounded />
        </MDTypography>
        <MDTypography
          variant="h2"
          color="dark"
          sx={{ ...navigationStyles, right: 0 }}
          ref={navigationNextRef}
        >
          <ChevronRightRounded />
        </MDTypography>
      </Swiper>
    </MDBox>
  );
}

export default ReviewsSlider;
